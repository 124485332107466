<template>
  <v-card class="elevation-0">
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Regions
          </v-col>
          <v-col>
            <v-btn class="float-right mr-5" color="primary" @click="showCreateItem()">Add</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Region', params: { regionID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>

        <v-card-title>
          <span class="headline">New region</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form ref="form">
                  <v-text-field v-model="item.name" label="Name" required :rules="[v => !!v || 'Name is required']"></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'RegionsTable',

  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '# countries', value: 'countries_count' },
        { text: 'Countries', value: 'countries_iso2s' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      item: {
        name: null,
      },
      showModal: false,
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'regions_options',
      endpoint: '/a/common/regions',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    closeModal: function () {
      this.item.name = null
      this.showModal = false
    },

    fetchData: function () {
      this.loading = true
      var myURL = this.endpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // exclude ID 0 (None)
      myURL += '&id=-0'

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      var body = JSON.stringify({'name': this.item.name})
      this.$http.post(this.endpoint, body).then(resp => {
        this.$store.dispatch('common/loadRegions')
        .catch(errResp => {
          this.$store.commit('system/setAPIError', { method: 'GET', url: 'common/loadRegions', description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
        })
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.showModal = true
    }
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
.theme--light.v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>